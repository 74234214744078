












































































































































































































































































































































































import eventBus from "../../../services/eventBus";
import Vue from "vue";
import store, { FlowStatus, InvoiceFlowStore } from "../services/InvoiceFlowStore";
import gStore, { GeneralIngoingInvoiceStore } from "../../generalIngoingInvoice/services/GeneralIngoingInvoiceStore";
import pStore, { SerPartStore } from "../../serPart/services/SerPartStore";
import sStore, { SerStationStore } from "../../serStation/services/SerStationStore";
import uStore, { UserStore } from "../../user/services/UserStore";
import sgStore, { SerStationGroupStore } from "../../serStationGroup/services/SerStationGroupStore";
import hStore, { ProjectHeadStore } from "../../projectHead/services/ProjectHeadStore";
import cStore, { SerCarStore } from "../../serCar/services/SerCarStore";
import iStore, { InvoiceItemDimensionStore } from "../../invoiceItemDimension/services/InvoiceItemDimensionStore";
import igStore, { InvoiceGroupStore } from "../../invoiceGroup/services/InvoiceGroupStore";
import relStore, { FlowDocumentRelationStore } from "../../flowDocumentRelation/services/FlowDocumentRelationStore";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import ControllersView from "./ControllersView.vue";
import AcceptorsView from "./AcceptorsView.vue";
import auth from "../../../services/auth";
import permStore, { PermissionStore, InvoicePermissions } from "@/services/permissions";
import ExcelView from "./ExcelView.vue";
import MoneyDispay from "@/components/MoneyDisplay.vue";

import GeneralIngoingInvoiceItemMinEditModel from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItemMinEditModel";
import InvoiceGroupDisplay from "../../invoiceGroup/models/InvoiceGroupDisplay";
import { GridColumnProps } from "@progress/kendo-vue-grid";
import SetIncorrectInvoice from "../components/SetIncorrectInvoice.vue";
import FlowReviewGrid from "./FlowReviewGrid.vue";
import ApiResponse from "@/models/ApiResponse";
import http, { handleApiResponse } from "@/services/http";
import IncomingInvoiceAccItem from "../models/IncomingInvoiceAccItem";
import AccountingWindow from "../components/AccountingWindow.vue";
import InvoiceTemplateCreator from "../components/InvoiceTemplateCreator.vue";

import { ConfirmationMixin } from "@/mixins/confirmation";
import Confirmation from "@/components/Confirmation.vue";

import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  store: InvoiceFlowStore;
  gStore: GeneralIngoingInvoiceStore;
  iStore: InvoiceItemDimensionStore;
  pStore: SerPartStore;
  sStore: SerStationStore;
  uStore: UserStore;
  sgStore: SerStationGroupStore;
  hStore: ProjectHeadStore;
  cStore: SerCarStore;
  permStore: PermissionStore;
  igStore: InvoiceGroupStore;
  relStore: FlowDocumentRelationStore;

  invoiceId: number;

  sort: SortDescriptor[];
  filter: CompositeFilterDescriptor;

  creating: boolean;
  selectedField: string;

  dimensionCreate: boolean;

  acceptorExists: boolean;
  controllersViewVisible: boolean;
  acceptorsViewVisible: boolean;

  controlButtonVisible: boolean;
  acceptButtonVisible: boolean;
  tick: Date;

  excelVisible: boolean;
  message: string;
  settingsVisible: boolean;

  showAccounting: boolean;
  templateVisible: boolean;

  componentKey: number;
  configuration: AppConfiguration;
}

interface Methods {
  approvePartially(): void;
  approveSelection(): void;
  checkBeforeSave(): boolean;
  update(): void;
  accept(): void;
  setIncorrect(): void;
  reopen(): void;
  cancel(): void;
  selectedAccGroupChanged(): void;
  filterChange(e: { filter: CompositeFilterDescriptor }): void;
  sortChange(e: { sort: SortDescriptor[] }): void;
  createItem(): void;
  updateStat(invapprStat: number): void;
  setControllersViewVisible(): void;
  setAcceptorsViewVisible(): void;
  clearDimensions(): void;

  showMessage(value: string): void;

  preAccount(): void;
  next(): void;
}

interface Computed {
  isPageDisabled: boolean;
  filterDisabled: boolean;
  newAccGroups: InvoiceGroupDisplay[];
  isAcceptable: boolean;
  isIncorrectRecordingEnabled: boolean;
  isReopenEnabled: boolean;
  columns: GridColumnProps[];
  items: GeneralIngoingInvoiceItemMinEditModel[];

  options: object;

  currentUserName: string;

  selectableInvGroups: InvoiceGroupDisplay[];

  invoiceNotAccepted: boolean;
  acceptorNotFound: boolean;
  isRemaining: boolean;
  currentUserCanAccept: boolean;
  userAlreadyAccepted: boolean;

  remaining: number;
  isNext: boolean;
}

export default ConfirmationMixin.extend<Data, Methods, Computed, {}>({
  mixins: [ConfirmationMixin],

  components: {
    AccountingWindow,
    ControllersView,
    AcceptorsView,
    ExcelView,
    SetIncorrectInvoice,
    FlowReviewGrid,
    InvoiceTemplateCreator,
    Confirmation,
    MoneyDispay,
  },

  data() {
    return {
      store,
      gStore,
      iStore,
      pStore,
      sStore,
      uStore,
      sgStore,
      hStore,
      cStore,
      permStore,
      igStore,
      relStore,

      tick: new Date(),
      invoiceId: 0,
      sort: [],
      filter: {
        logic: "and",
        filters: [],
      },

      creating: false,
      selectedField: "selected",
      dimensionCreate: false,

      acceptorExists: false,
      controllersViewVisible: false,
      acceptorsViewVisible: false,

      controlButtonVisible: false,
      acceptButtonVisible: false,

      excelVisible: false,

      message: "",
      settingsVisible: false,

      showAccounting: false,
      templateVisible: false,

      componentKey: 0,
      configuration,
    };
  },

  async beforeMount() {
    this.componentKey = +(Math.random() * 100).toFixed(0);
    console.log(this.componentKey);
    
    window.scrollTo(0, 0);
    this.invoiceId = Number(this.$route.params.invoiceId) || 0;

    await this.store.loadAcceptorRequests(Number(this.$route.params.invoiceId));
    await this.store.loadControllerRequests(Number(this.$route.params.invoiceId));

    const isContrUser = this.store.controllerRequests.find((x) => x.name == this.currentUserName) ?? 0;
    const isAccepUser = this.store.acceptorRequests.find((x) => x.name == this.currentUserName) ?? 0;

    if (isContrUser != 0) {
      this.controlButtonVisible = true;
      this.acceptButtonVisible = false;
    }
    if (isAccepUser != 0) {
      this.controlButtonVisible = false;
      this.acceptButtonVisible = true;
    }

    await this.store.loadProjectData(this.invoiceId);
    await this.gStore.loadAllTaxes();
    await this.gStore.loadAllUnits();

    await this.iStore.loadInvItemAndDimensionsById(Number(this.$route.params.invoiceId));

    this.store.selectedAccGroup = this.store.accGroups.find((x) => x.id == this.store.invoice.accGroup);
    if (!this.store.selectedAccGroup && this.store.accGroups.length > 0) {
      this.store.selectedAccGroup = this.store.accGroups[0];
      this.store.invoice.accGroup = this.store.selectedAccGroup.id;
    }
  },

  destroyed() {
    this.iStore.invItemAndDimensionsById = [];
  },

  methods: {
    async preAccount(){
      this.showAccounting = !this.showAccounting;
      
      if(!this.showAccounting) return;


      const invoiceID = this.store.invoice.id;
      this.store.accountItems = await http.post<ApiResponse<IncomingInvoiceAccItem[]>>(`accounting/${invoiceID}/test-pre-accounting`)
      .then(handleApiResponse);
    },

    async approvePartially() {
      const vatReturn = await this.store.checkVatReturn(this.store.invoice.vatDate, this.store.invoice.id);
      if (vatReturn == -2) {
        console.log("-2");
        eventBus.$emit("warn", "A számla olyan időszakba esik, amelyre már van megnyitva áfa bevallás, az áfa bevallást ezért frissítenie kell!");
      }
      else if (vatReturn == -1) {
        console.log("-1");
        const confirmed = await this.confirm("A számla lezárt ÁFA időszakot érint, folytatja?");
        if (!confirmed) {
          return;
        }
      }
      else if(vatReturn == 1) {
        console.log("1");
        this.store.invoice.latecomer = true;
      }
      else if(vatReturn == 0) {
        console.log("0");
        this.store.invoice.latecomer = false;
      }
      await this.store.approvePartially(Number(this.$route.params.invoiceId));

      if (this.configuration.sysParams.isKanriEnabled) {
        await this.store.kanriSync(Number(this.$route.params.invoiceId));
      }
    },

    async approveSelection() {
      const vatReturn = await this.store.checkVatReturn(this.store.invoice.vatDate, this.store.invoice.id);
      if (vatReturn == -2) {
        console.log("-2");
        eventBus.$emit("warn", "A számla olyan időszakba esik, amelyre már van megnyitva áfa bevallás, az áfa bevallást ezért frissítenie kell!");
      }
      else if (vatReturn == -1) {
        console.log("-1");
        const confirmed = await this.confirm("A számla lezárt ÁFA időszakot érint, folytatja?");
        if (!confirmed) {
          return;
        }
      }
      else if(vatReturn == 1) {
        console.log("1");
        this.store.invoice.latecomer = true;
      }
      else if(vatReturn == 0) {
        console.log("0");
        this.store.invoice.latecomer = false;
      }
      await this.store.approveSelection().then(() => this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated"));

      if (this.configuration.sysParams.isKanriEnabled) {
        await this.store.kanriSync(Number(this.$route.params.invoiceId));
      }
    },

    checkBeforeSave() {
      if (!this.store.invoice.invoiceNo) {
        eventBus.$emit("error", "A bizonylat szám megadása kötelező!");
        return false;
      }

      if (!(this.store.invoice.tax > 1)) {
        eventBus.$emit("error", "Az ÁFA kulcs megadása kötelező!");
        return false;
      }

      return true;
    },

    showMessage(value: string) {
      this.message = value;
    },

    async clearDimensions() {
      await this.iStore.clearAllDimensions(Number(this.$route.params.invoiceId));
      this.$router.push(`/general-incoming-invoice/flow/edit/${this.$route.params.invoiceId}/4`);
    },

    setAcceptorsViewVisible() {
      if (!this.acceptorsViewVisible) {
        this.acceptorsViewVisible = true;
      } else this.acceptorsViewVisible = false;
    },

    setControllersViewVisible() {
      if (!this.controllersViewVisible) {
        this.controllersViewVisible = true;
      } else this.controllersViewVisible = false;
    },

    async updateStat(invapprStat: number) {
      await this.store
        .updateAcceptRequestStatus(invapprStat)
        .then(() => this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated"));
      await this.store.loadAcceptorRequests(Number(this.$route.params.invoiceId));
      await this.store.loadControllerRequests(Number(this.$route.params.invoiceId));
      await this.store.loadFlowStatusRelations(Number(this.$route.params.invoiceId));
    },

    async createItem() {
      this.gStore.invoiceItemMinEditModelByInvId.invNo = Number(this.$route.params.invoiceId);
      await this.gStore.createInvoiceItemMinEditModel();
      this.creating = !this.creating;
      window.scrollTo(0, 0);
    },

    filterChange(e: { filter: CompositeFilterDescriptor }) {
      this.filter = e.filter;
    },

    sortChange(e: { sort: SortDescriptor[] }) {
      this.sort = e.sort;
    },

    async update() {
      await this.store.update().then(() => {
        this.$emit("update");
      });
    },

    async accept() {
      if (this.checkBeforeSave()) {
        await this.store.accept().then(async () => {
          await Promise.all([this.store.loadInvoice(this.invoiceId), this.store.loadInvoiceDisplay(this.invoiceId)]);
        });
      }
    },

    async setIncorrect() {
      await this.store.setIncorrect();
    },

    async reopen() {
      await this.store.reopen();
    },

    cancel() {
      this.$router.push(`/general-incoming-invoice`);
    },

    selectedAccGroupChanged() {
      this.store.invoice.accGroup = this.store.selectedAccGroup?.id ?? 1;
    },

    next() {
      this.$router.push(`/general-incoming-invoice/flow/edit/${this.store.invoice.id}/6`);
    },
  },

  computed: {
    isNext() {
      return this.store.flowStatusRelations.length > 4;
    },

    remaining() {
      return gStore.invoiceItemMinEditModelsByInvId.reduce((acc, curr) => acc + curr.remaining, 0)
    },

    currentUserCanAccept() {
      let ret = false;
      if (
        this.store.acceptorRequests != null &&
        this.store.acceptorRequests.find((x) => !x.isFullFillReq && x.name == auth.userName)
      ) {
        ret = true;
      }
      return ret;
    },

    userAlreadyAccepted() {
      let ret = false;
      if (
        this.store.acceptorRequests != null &&
        this.store.acceptorRequests.find((x) => !x.isFullFillReq && x.name == auth.userName && !x.accepted)
      ) {
        ret = true;
      }
      return ret;
    },

    acceptorNotFound() {
      let ret = false;
      if (this.store.acceptorRequests != null && store.acceptorRequests.find((x) => !x.isFullFillReq) == null) {
        ret = true;
      }
      return ret;
    },

    isRemaining() {
      let ret = false;
      const itemList = this.gStore.invoiceItemMinEditModelsByInvId.filter((i) => !i.isStockRelated && !i.isService);
      if (itemList.map((i) => i.remaining).reduce((a, b) => a + b, 0) > 0) {
        ret = true;
      }
      return ret;
    },

    invoiceNotAccepted() {
      let ret = true;
      if (this.store.acceptorRequests == null) {
        return ret;
      }

      const ffs = this.store.acceptorRequests.filter((y) => y.isFullFillReq);

      if (
        ffs.length > 0 &&
        ffs.some((y) => y.accepted) &&
        this.store.acceptorRequests.find((x) => !x.isFullFillReq && x.name == auth.userName && !x.accepted)
      ) {
        ret = false;
      }
      return ret;
    },

    selectableInvGroups() {
      const invoiceGroups = this.igStore.invoiceGroups as InvoiceGroupDisplay[];
      return invoiceGroups;
    },

    filterDisabled() {
      if (this.store.invoice.type == 9 || this.store.invoice.type == 2 || this.store.invoice.type == 3 || this.store.invoice.rectify || this.store.invoice.dpaym) {
        return true;
      }
      return false;
    },

    isPageDisabled() {
      const stepDone = this.store.flowStatusRelations.some(
        (x) => x.statusId === FlowStatus.ControllingAccepting && !x.deleted
      );
      if (stepDone) {
        return true;
      }

      if (this.permStore.isAllInOneRecorder) {
        return false;
      }

      const relations = [FlowStatus.FinancialRecording, FlowStatus.StockRelationRecording];
      return (
        !this.permStore.hasInvoice([InvoicePermissions.Controlling, InvoicePermissions.Accepting]) ||
        !this.store.flowStatusRelations.some((x) => relations.includes(x.statusId) && !x.deleted)
      );
    },

    columns() {
      return [
        {
          width: 30,
          cell: "selectionTemplate",
          filterable: false,
        },
        {
          width: 50,
          title: "",
          cell: "addInvoiceGroupTemplate",
          filterable: false,
        },
        {
          title: "Tétel",
          field: "name",
          className: "center_text",
        },
        {
          title: "Megjegyzés",
          field: "note",
          className: "center_text",
        },
        {
          title: "Egységár",
          field: "unitPrice",
          format: "{0: #,##.##}",
          className: "center_text",
        },
        {
          title: "Mennyiség",
          field: "quantity",
          format: "{0: #,##.##}",
          className: "center_text",
        },
        {
          title: "Mee.",
          field: "unit",
          className: "center_text",
        },
        {
          title: "ÁFA kulcs",
          field: "tax",
          className: "center_text",
        },
        {
          title: "Nettó",
          field: "currNetto",
          format: "{0: #,##.##}",
          className: "center_text",
        },
        {
          title: "ÁFA",
          field: "currVat",
          format: "{0: #,##.##}",
          className: "center_text",
        },
        {
          title: "Bruttó",
          field: "currBrutto",
          format: "{0: #,##.##}",
          className: "center_text",
        },
        {
          title: "Fennmaradó",
          field: "remaining",
          format: "{0: #,##.##}",
          className: "center_text",
        },
        {
          width: 0,
          filterable: false,
        },
      ]
        .filter((i) => (this.acceptButtonVisible ? true : i.cell !== "selectionTemplate"))
        .filter((i) =>
          this.acceptButtonVisible || this.controlButtonVisible ? true : i.cell !== "addInvoiceGroupTemplate"
        );
    },

    currentUserName() {
      return auth.userName;
    },

    items() {
      return orderBy(filterBy(this.gStore.invoiceItemMinEditModelsByInvId, this.filter), this.sort);
    },

    newAccGroups() {
      return this.store.accGroups.filter((a) => !a.deleted);
    },

    isAcceptable() {
      return this.store.invoice.preStatus == 8 || this.store.invoice.preStatus == 6;
    },

    isIncorrectRecordingEnabled() {
      return this.store.invoice.preStatus == 2 && this.store.invoice.id > 1;
    },

    isReopenEnabled() {
      return this.store.invoice.preStatus == 8 || this.store.invoice.preStatus == 6; //&& !stockOnAccept;
    },

    options() {
      return {
        duration: 800,
        offset: 0,
        easing: "easeInOutQuad",
      };
    },
  },
});















import Vue from "vue";
import { ExtOrderItem, InvoiceItem, InvoiceRelationType, SalesAndOpportunity, Stock } from "../models";
import { formatDate } from "@/utils/formatDates";

const displayNames = (item: InvoiceItem) => {
  const stock = item.relations.find((i) => i.type === InvoiceRelationType.Stock)?.relatedItem as Stock;

  if (stock && !item.isService) {
    const result = [`Szállítólevél: ${stock.deliveryNumber ?? "-"}`];

    if (stock.orderNumber) {
      result.push(`Rendelés száma: ${stock.orderNumber}`);
    }

    return result;
  }

  const orderItem = item.relations.find((i) => i.type === InvoiceRelationType.ExtOrderItem)
    ?.relatedItem as ExtOrderItem;
  if (orderItem) {
    return [
      orderItem.orderNumber ? `Rendelés száma: ${orderItem.orderNumber}` : item.orderNumber ? item.orderNumber : "",
      (orderItem.originalStock > 1) ? `${orderItem.originalStock} stock cseréje` : ""
    ].filter((i) => i);
  }

  const salesOrderRelation = item.relations.find((r) => r.type === InvoiceRelationType.SalesOrder);
  if (salesOrderRelation) {
    const salesOrderData = salesOrderRelation.relatedItem as SalesAndOpportunity;
    return [`Értékesítés: ${salesOrderData.salesOrderNo}`];
  }

  if (item.car > 1 || item.prCar > 1) {
    const ret = [`Modell: ${item.carDetails.model}`, `Alvázszám: ${item.carDetails.bodyNo}`];
    if (item.car > 1) {
      ret.unshift(`Gépjármű: ${item.car}`);
    } else {
      ret.unshift(`Gépjármű rendelés: ${item.prCar}`);
    }
    return ret;
  }

  if ((item as any).bodyNo) {
    const ret = [`Alvázszám: ${(item as any).bodyNo}`];
    return ret;
  }

  return [];
};

const toolTipInfos = (item: InvoiceItem) => {
  const stock = item.relations.find((i) => i.type === InvoiceRelationType.Stock)?.relatedItem as Stock;
  const result = [];
  if (stock) {
    result.push(`Betárolás: ${stock.storeInID}`);
    result.push(`Betárolás dátuma: ${formatDate(new Date(stock.stockDate))}`);
    result.push(`Batch: ${stock.batchNumber}`);
  }

  return result;
}

export default Vue.extend({
  props: {
    item: {
      type: Object as () => InvoiceItem,
    },
  },

  computed: {
    displayNames() {
      return displayNames(this.item);
    },

    toolTipInfos() {
      return toolTipInfos(this.item);
    }
  },
});
